/* You can add global styles to this file, and also import other style files */

$disabled: #cdcdce;
$success: #25bc86;
$blueBg: #0060a8;
$error: #ff0000;
$secondaryText: #808080;
$white: #fff;

// .ant-steps-item {
//   &.ant-steps-item-active {
//     .ant-steps-item-icon {
//       color: #ffffff;
//       background-color: #8a697c !important;
//     }
//   }
// }

.ni-steps.ant-steps.ant-steps-vertical {
  &.ant-steps-default
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: $disabled !important;
  }

  &.ant-steps-default
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: $secondaryText !important;
  }

  &.ant-steps-default
    > .ant-steps-item:last-child
    > .ant-steps-item-container
    > .ant-steps-item-content {
    min-height: initial;
  }

  &.ant-steps-default
    > .ant-steps-item.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    background-color: $success !important;
    border-color: $success !important;
  }

  &.ant-steps-default
    > .ant-steps-item.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: $success !important;
  }

  &.ant-steps-default
    > .ant-steps-item.ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    background-color: $blueBg;
    border-color: $blueBg;
  }

  &.ant-steps-default
    > .ant-steps-item.ant-steps-item-error
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    background-color: $error;
    border-color: $error;
  }

  &.ant-steps-default
    > .ant-steps-item.ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    background-color: $disabled;
    border-color: $disabled;
  }

  &.ant-steps-default
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-icon
    > .ant-steps-icon {
    color: $white;
  }
}

.ni-steps.ant-steps.ant-steps-horizontal {
  &.ant-steps-default
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: $disabled;
  }

  &.ant-steps-default
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: $secondaryText;
  }

  &.ant-steps-default
    > .ant-steps-item.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    background-color: $success;
    border-color: $success;
  }

  &.ant-steps-default
    > .ant-steps-item.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: $success;
  }

  &.ant-steps-default
    > .ant-steps-item.ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    background-color: $blueBg;
    border-color: $blueBg;
  }

  &.ant-steps-default
    > .ant-steps-item.ant-steps-item-error
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    background-color: $error;
    border-color: $error;
  }

  &.ant-steps-default
    > .ant-steps-item.ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    background-color: $disabled;
    border-color: $disabled;
  }

  &.ant-steps-default
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-icon
    > .ant-steps-icon {
    color: $white;
  }

  // labePlacement - vertical
  &.ant-steps-label-vertical.ant-steps-default
    > .ant-steps-item.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: $success;
    border-color: $success;
  }
}

.ant-select-item {
  font-weight: 400 !important;
  &.ant-select-item-option-active {
    background-color: #f5fcf5 !important;
    .ant-select-item-option-content {
      font-weight: 400 !important;
      color: #000000 !important;
    }
  }
  &.ant-select-item-option-selected {
    background-color: #2eb135 !important;
    font-weight: 400 !important;
    .ant-select-item-option-content {
      color: #fff !important;
    }
  }
}

.ant-layout-header {
  background-color: #ffffff !important;
}

.cards-list-container {
  background-color: #ffffff !important;
  box-shadow: 0px 0px 4px 0px #60606040;
}

.transaction-list-day-title {
  color: #6d5468 !important;
}

.profile-section-icon {
  > * {
    fill: #6d5468 !important;
  }
}

.card-list-btn-item {
  border-color: #cdcdce !important;
  box-shadow: none !important;
}
